<template>
  <layout :loading="loading">
    <div class="book">
      <div class="book__header d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <b-button
            variant="secondary"
            class="book__back-button"
            @click.prevent="goToLibraryPage"
          >
            <i class="bx bx-arrow-back font-size-16" />
          </b-button>
          <div class="book__title d-none d-sm-block">Book Details</div>
        </div>
        <div class="d-flex">
          <b-button
            v-if="isBookTaken"
            :disabled="buttonLoading"
            variant="warning"
            class="mr-sm-2 book__take-btn"
            @click="handleReturnBook"
          >
            <b-spinner v-if="buttonLoading" small label="Spinning" class="mr-2" />
            Return Book back
          </b-button>
          <b-button
            v-else-if="book && !book.employee_uid"
            :disabled="buttonLoading"
            variant="success"
            class="mr-sm-2 book__take-btn"
            @click="handleTakeBook"
          >
            <b-spinner v-if="buttonLoading" small label="Spinning" class="mr-2" />
            Take this Book
          </b-button>
          <b-button
            v-if="$hasAccess(['admin', 'office_manager'])"
            variant="primary"
            class="d-none d-sm-inline"
            @click="handleOpenBookModal"
          >
            <div class="d-flex align-items-center">
              <i class="bx bxs-pencil mr-2 font-size-16" />
              Edit Book
            </div>
          </b-button>
        </div>
      </div>
      <b-card class="book__card">
        <div class="book__container">
          <div class="book__first-column">
            <div
              class="book__cover"
              :style="{ backgroundColor: !book.photoUrl ? book.cover_color : '#53565B' }"
            >
              <img v-if="book.photoUrl" :src="book.photoUrl" alt="" />
              <template v-else>
                <div class="book__name">{{ book.title }}</div>
                <hr class="book__divider" />
                <div class="book__author">{{ book.author }}</div>
              </template>
            </div>
            <div class="book__likes likes">
              <div :class="['likes__item', {'likes__item_green': isBookLiked }]">
                <div @click="handleLikeBook(true)">
                  <i class="bx bxs-like" />
                </div>
                <span>{{ book.likes }}</span>
              </div>
              <div :class="['likes__item', {'likes__item_red': isBookDisliked }]">
                <div @click="handleLikeBook(false)">
                  <i class="bx bxs-dislike" />
                </div>
                <span>{{ book.unlikes }}</span>
              </div>
            </div>
          </div>
          <div class="book__second-column">
            <div class="book__tags">
              <book-tag
                v-for="tag in tags"
                :key="tag.title"
                :tag="tag.title"
              />
            </div>
            <div class="book__name">{{ book.title }}</div>
            <div class="book__author">{{ book.author }}</div>
            <div
              v-if="book.category"
              class="book__category"
            >
              #{{ book.category.title }}
            </div>
            <div
              v-if="description"
              class="book__description"
            >
              {{ description }}
              <span
                v-if="windowWidth < 768 && !readMore"
                @click="readMore = true"
                class="book__read-more"
              >
                Read more
              </span>
            </div>
            <hr v-if="bookReviews.length" class="book__divider" />
            <div v-if="bookReviews.length">
              <div class="book__reviews-count">
                {{ bookReviews.length }} coworkers recommend this book
              </div>
              <div class="book__reviews reviews">
                <b-avatar
                  v-for="review in bookReviews"
                  :key="review.id"
                  variant="info"
                  v-b-tooltip.hover
                  class="reviews__item"
                  :title="review.employee.name"
                  :src="review.employee.photo"
                />
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <book-modal
      :open="isBookModalOpen"
      :mode="modalMode"
      :book="book"
      :inventoryCode="book?.inventory_code"
      @onHide="handleCloseBookModal"
      @onSubmit="handleUpdateBook"
      @onDelete="handleDeleteBook"
    />
  </Layout>
</template>

<script>
import get from 'lodash/get';
import { mapActions } from 'vuex';
import { FORM_MODE } from '@/utils/constants';
import BookModal from '@/components/book-modal.vue';
import { convertObjectToFormData } from '@/utils/converters';
import BookTag from '@/components/library/book-tag.vue';

export default {
  name: 'Book',
  components: { BookModal, BookTag },
  data() {
    return {
      get,
      currentId: null,
      prevRouteName: null,
      isBookModalOpen: false,
      modalMode: FORM_MODE.EDIT,
      loading: false,
      readMore: false,
      buttonLoading: false,
      windowWidth: window.innerWidth,
    }
  },
  async mounted() {
    const { id } = this.$route.params;
    if (id) {
      this.currentId = id;
    }
    this.loading = true;
    await this.getLibrarySettings();
    await this.fetchBook();
    this.loading = false;
    window.addEventListener('resize', this.handleResizeWindow);
  },
  beforeDestroy() {
    this.$store.dispatch('library/clearBook');
    window.removeEventListener('resize', this.handleResizeWindow);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRouteName = from.name;
    });
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    book() {
      return this.$store.state.library.book;
    },
    isBookTaken() {
      return get(this.book,'employee.uid', 0) === get(this.user, 'employee.uid', 1);
    },
    reviews() {
      return this.book?.reviews ?? [];
    },
    bookReviews() {
      return this.reviews.filter(rev => rev.is_like);
    },
    isBookLiked() {
      return this.reviews.some((review) => review.is_like && review.employee_uid === this.user.employee.uid);
    },
    isBookDisliked() {
      return this.reviews.some((review) => !review.is_like && review.employee_uid === this.user.employee.uid);
    },
    tags() {
      return (this.book?.labels || []).map((label) => ({
        ...label,
        title: label.title === 'not available'
          ? `${this.$moment(this.book.taken_date).format('DD.MM.YYYY')} taken by ${this.book.employee.name}`
          : label.title
      }))
    },
    description() {
      if (!this.book?.description) {
        return '';
      }
      if (this.windowWidth < 768 && !this.readMore) {
        return `${this.book.description.slice(0, 700)}...`;
      }

      return this.book.description;
    }
  },
  methods: {
    ...mapActions('library', [
      'getBook',
      'likeBook',
      'updateBook',
      'deleteBook',
      'uploadBookCover',
      'getLibrarySettings'
    ]),
    async fetchBook() {
      await this.getBook(this.currentId);
    },
    async handleUpdateBook({ data, file, isCoverChange }) {
      this.loading = true;
      this.handleCloseBookModal();
      const response = await this.updateBook({ id: this.currentId, data });
      if (response?.id && isCoverChange) {
        await this.uploadBookCover({
          id: response.id,
          data: file ? convertObjectToFormData({ file }) : { file }
        })
      }
      await this.fetchBook();
      this.loading = false;
    },
    async handleDeleteBook() {
      this.loading = true;
      await this.handleCloseBookModal();
      await this.deleteBook(this.currentId);
      this.loading = false;
      this.goToLibraryPage();
    },
    async handleReturnBook() {
      this.buttonLoading = true;
      await this.$store.dispatch('library/returnBook', this.currentId);
      await this.fetchBook();
      this.buttonLoading = false;
    },
    async handleTakeBook() {
      this.buttonLoading = true;
      await this.$store.dispatch('library/takeBook', {
        id: this.currentId,
        data: convertObjectToFormData({ _method: 'PUT' })
      });
      await this.fetchBook();
      this.buttonLoading = false;
    },
    handleOpenBookModal() {
      this.isBookModalOpen = true;
    },
    handleCloseBookModal() {
      this.isBookModalOpen = false;
    },
    goToLibraryPage() {
      if (this.prevRouteName === 'library') {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: 'library' });
      }
    },
    async handleLikeBook(isLike) {
      if ((isLike && !this.isBookLiked) || (!isLike && !this.isBookDisliked)) {
        this.loading = true;
        await this.likeBook({
          employee_uid: this.user.employee.uid,
          library_item_id: this.currentId,
          is_like: isLike
        });
        await this.fetchBook();
        this.loading = false;
      }
    },
    handleResizeWindow() {
      this.windowWidth = window.innerWidth;
    }
  }
}
</script>

<style lang="scss" scoped>
.book {
  &__header {
    margin-bottom: 55px;
  }
  &__back-button {
    width: 36.53px;
    height: 36.53px;
    position: relative;
    border: none;
    margin-right: 20px;
    background-color: #BBB;

    &:active {
      background-color: #BBB !important;
    }

    i {
      top: 50%;
      left: 50%;
      color: #fff;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
  &__take-btn {
    display: flex;
    align-items: center;
  }
  &__title {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px
  }
  &__card {
    border-radius: 20px;
  }
  &__container {
    display: grid;
    color: #2A3042;
    grid-column-gap: 30px;
    grid-template-columns: 280px 1fr;
  }
  &__cover {
    width: 100%;
    height: 400px;
    display: flex;
    padding: 10px;
    overflow: hidden;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #e2e2e5;

    img {
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      margin: -10px;
      object-fit: cover;
    }
  }
  &__tags {
    gap: 5px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  .book-tag {
    font-size: 13px;
    line-height: 20px;
  }
  &__divider {
    height: 1px;
    border: none;
    width: 100%;
    margin: 15px 0px;
    background: rgba(0, 0, 0, 0.05);
  }
  &__name {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    margin-bottom: 5px;
  }
  &__author {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 5px;
  }
  &__cover &__divider {
    background: rgba(255, 255, 255, 0.20);
  }
  &__cover &__name {
    color: #fff;
    text-align: center;
  }
  &__cover &__author {
    color: #fff;
    opacity: 0.6;
    font-size: 20px;
    text-align: center;
  }
  &__category {
    opacity: .4;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  &__description {
    color: #343A40;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin-top: 15px;
  }
  &__reviews-count {
    color: #28A745;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    padding-top: 5px;
    margin-bottom: 10px;
  }
  &__likes {
    margin-top: 20px;
  }
  &__read-more {
    color: #556EE6;
  }
}
.reviews {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: 60px;
    height: 60px;
  }
}
.likes {
  gap: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__item {
    & > div {
      width: 32px;
      height: 32px;
      display: flex;
      cursor: pointer;
      border-radius: 50%;
      background: #BBB;
      align-items: center;
      justify-content: center;

      i {
        color: #fff;
        font-size: 16px;
      }
    }
    &_green > div {
      background: #28A745;
    }
    &_red > div {
      background: #DC3545;
    }
    span {
      display: block;
      margin-top: 10px;
      color: #74788D;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 15px;
      text-align: center;
    }
  }
}
@media (max-width: 768px) {
  .book {
    &__header {
      margin-bottom: 20px;
    }
    &__container {
      display: block;
    }
    &__cover {
      height: 300px;
    }
    &__first-column {
      display: grid;
      grid-column-gap: 20px;
      grid-template-columns: 200px 1fr;
    }
    &__likes {
      margin-top: 0px;
    }
    &__first-column {
      margin-bottom: 20px;
    }
  }
  .likes {
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    &__item {
      display: flex;
      align-items: center;

      span {
        margin-top: 0px;
        margin-left: 10px;
      }
    }
  }
  .reviews {
    &__item {
      width: 45px;
      height: 45px;
    }
  }
}
</style>
